<template>
  <div :class="$style.products">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')"
        >Создать</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="8.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input @input="handleNameInput" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Артикул" prop="vendorCode">
        <el-input v-model="form.vendorCode"></el-input>
      </el-form-item>

      <el-form-item
        label="Отображать на сайте"
        prop="isActive"
        label-width="12rem"
      >
        <input
          type="checkbox"
          :checked="form.isActive"
          v-model="form.isActive"
        />
      </el-form-item>

      <el-form-item label="МойСклад ID" prop="moySkladID">
        <el-input v-model="form.moySkladID" />
      </el-form-item>
      <el-form-item label="Предназначение" prop="purpose">
        <el-input v-model="form.purpose" />
      </el-form-item>
      <el-form-item label="Бренд" prop="brandId">
        <el-select
          v-model="form.brandId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMoreBrands"
          filterable
          remote
          :remote-method="searchBrands"
          :loading="brandsLoading"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Категории" prop="categories">
        <el-cascader
          style="width: 100%"
          :options="categoriesOptions"
          v-model="form.categories"
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            multiple: true,
          }"
          clearable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="Ассортименты" prop="assortments">
        <el-select
          v-model="form.assortments"
          filterable
          placeholder="Выбрать"
          clearable
          multiple
          remote
          :remote-method="searchAssortments"
          v-el-select-loadmore="loadMoreAssortments"
          :loading="assortmentsLoading"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in assortments"
            :key="item.id + item.slug + index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Группа" prop="groupId">
        <el-select
          v-model="form.groupId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMoreGroups"
          @change="getAttributeList"
        >
          <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Атрибуты" v-if="form.groupId">
        <br />
        <el-form-item
          v-for="att in attributes"
          :key="att.id"
          :label="att.name"
          :class="$style.filterItem"
          label-width="0"
        >
          <el-select
            v-model="att.valueOptions"
            multiple
            placeholder="Выберите"
            style="width: 100%"
          >
            <el-option
              v-for="item in att.attributeOptions"
              :key="item.id"
              :label="item.value"
              :value-key="item.id"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form-item>

      <el-form-item label="Подарок" prop="presentId">
        <el-select
          v-model="form.presentId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMorePresents"
          filterable
          remote
          clearable
          :remote-method="searchPresents"
          :loading="presentsLoading"
        >
          <el-option
            v-for="item in presents"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Приоритет" prop="priority">
        <el-input-number
          v-model.number="form.priority"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>

      <div :class="$style.prices">
        <el-form-item label="Себестоимость" prop="costPrice">
          <el-input-number
            v-model.number="form.costPrice"
            controls-position="right"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="Цена" prop="price">
          <el-input-number
            v-model.number="form.price"
            controls-position="right"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="После скидки" prop="discountPrice">
          <el-input-number
            v-model.number="form.discountPrice"
            controls-position="right"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
      </div>
      <el-form-item label="Описание" prop="description">
        <TextEditor :value.sync="form.description" />
      </el-form-item>

      <SeoBlock
        :hasSetDefaultFlag="true"
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import { transliterate } from '@/helpers/slug'
import { debounce } from '@/helpers/debounce.js'

import TextEditor from '@/components/atoms/TextEditor.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

import { transformCascadeData } from '@/helpers/addwine/index.js'

export default {
  components: { SeoBlock, TextEditor },
  data() {
    return {
      groups: [],
      groupsOptions: {
        limit: 50,
        page: 1,
        total: 0,
      },
      brands: [],
      brandsLoading: false,
      brandsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderDir: 'asc',
        orderBy: 'name',
      },
      categoriesOptions: [],
      assortments: [],
      assortmentsLoading: false,
      assortmentsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderDir: 'asc',
        orderBy: 'name',
      },
      presents: [],
      presentsLoading: false,
      presentsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderDir: 'asc',
        orderBy: 'name',
      },
      attributes: [],
      form: {
        name: '',
        slug: '',
        moySkladID: '',
        groupId: '',
        brandId: '',
        presentId: '',
        description: '',
        vendorCode: '',
        categories: [],
        assortments: [],
        images: [],
        priority: 0,
        isActive: false,
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        vendorCode: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        moySkladID: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            message: 'Неверный формат',
            validator(rule, value, callback) {
              if (!regexp.uuid.test(value)) {
                return callback(false)
              }
              return callback()
            },
          },
        ],
        groupId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        brandId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        categories: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        costPrice: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
      inputTagVisible: false,
      inputTagValue: '',
    }
  },
  created() {
    this.getGroupList()
    this.getBrandList()
    this.getCategoryList()
    this.getAssortmentList()
    this.getPresentList()
    this.brandSearch = debounce(this.getBrandList, 500)
    this.categorySearch = debounce(this.getCategoryList, 500)
    this.assortmentSearch = debounce(this.getAssortmentList, 500)
    this.presentSearch = debounce(this.getPresentList, 500)
  },
  methods: {
    loadMoreGroups() {
      const { page, limit, total } = this.groupsOptions
      if (limit * page < total) {
        this.groupsOptions.page++
        this.getGroupList()
      }
    },
    loadMoreBrands() {
      const { page, limit, total } = this.brandsOptions
      if (limit * page < total) {
        this.brandsOptions.page++
        this.getBrandList()
      }
    },
    async searchBrands(search) {
      this.brandsOptions.page = 1
      this.brandsOptions.search = search
      this.brandsLoading = true
      this.brands = []
      await this.brandSearch()
    },
    loadMoreAssortments() {
      const { page, limit, total } = this.assortmentsOptions
      if (limit * page < total) {
        this.assortmentsOptions.page++
        this.getAssortmentList()
      }
    },
    async searchAssortments(search) {
      this.assortmentsOptions.page = 1
      this.assortmentsOptions.search = search
      this.assortmentsLoading = true
      this.assortments = []
      await this.assortmentSearch()
    },
    loadMorePresents() {
      const { page, limit, total } = this.presentsOptions
      if (limit * page < total) {
        this.presentsOptions.page++
        this.getPresentList()
      }
    },
    async searchPresents(search) {
      this.presentsOptions.page = 1
      this.presentsOptions.search = search
      this.presentsLoading = true
      this.presents = []
      await this.presentSearch()
    },

    async getGroupList() {
      const { value, error } =
        await delivery.ProductsCore.GroupsActions.getList({
          limit: this.groupsOptions.limit,
          page: this.groupsOptions.page,
          orderDir: 'asc',
          orderBy: 'name',
        })
      if (error) return
      this.groups = [...this.groups, ...value.data]
      this.groupsOptions.total = value.meta.count
    },

    async getCategoryList() {
      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getTree()
      if (error) return

      this.categoriesOptions = [...value.children]
    },

    async getAssortmentList() {
      this.assortmentsLoading = true

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList(
          this.assortmentsOptions,
        )
      if (error) return
      this.assortments = [...this.assortments, ...value.data]
      this.assortmentsOptions.total = value.meta.count

      this.assortmentsLoading = false
    },

    async getBrandList() {
      this.brandsLoading = true
      const { value, error } =
        await delivery.ProductsCore.BrandsActions.getList(this.brandsOptions)
      if (error) return
      this.brands = [...this.brands, ...value.data]

      this.brandsOptions.total = value.meta.count

      this.brandsLoading = false
    },
    async getPresentList() {
      this.presentsLoading = true

      const { value, error } =
        await delivery.ProductsCore.ProductsActions.getList(
          this.presentsOptions,
        )
      if (error) return
      this.presents = [...this.presents, ...value.data]
      this.presentsOptions.total = value.meta.count

      this.presentsLoading = false
    },
    async getAttributeList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList({
          limit: this.limit,
          page: this.page,
          groupId: this.form.groupId,
        })
      if (error) return
      this.attributes = value.data
      this.attributes.forEach((attr) => {
        attr.attributeOptions.sort((x, y) => {
          if (x.value.toLowerCase() < y.value.toLowerCase()) return -1
          else return 1
        })
      })
      this.total = value.meta.count

      loading.close()
    },

    handleNameInput(value) {
      this.form.slug = transliterate(value)
      this.form.seo.title = value
      this.form.seo.description = value
      this.form.seo.ogTitle = value
      this.form.seo.ogDescription = value
    },
    uploadImages(images) {
      this.form.images = images
    },

    resetForm() {
      this.form = {
        name: '',
        slug: '',
        seo: {},
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = { ...this.form }

          this.form.attributeValues = this.attributes
            .filter((item) => {
              return item?.valueOptions?.length
            })
            .map((item) => {
              return {
                attributeId: item.id,
                attributeOptions: item.valueOptions,
              }
            })

          const transformedCategories = transformCascadeData(
            this.form.categories,
          )

          const result = await delivery.ProductsCore.ProductsActions.create({
            ...data,
            categories: transformedCategories,
          })

          loading.close()
          if (result.error) {
            alert('Ошибка создания продукта: ' + result.error)
          } else {
            alert('Продукт успешно создан')
            this.resetForm()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.products {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .block {
    & > div {
      width: fit-content;
      input {
        height: 2rem;
        width: 18.5rem;
      }
      svg {
        top: 1rem;
      }
    }
  }
  .prices {
    display: flex;
  }
  label {
    margin: 0;
  }
  .filterItem > label {
    width: auto !important;
  }
}
</style>
