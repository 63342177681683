const transformCascadeData = (data) => {
    const transformedData = [];

    for (let i = 0; i < data.length; i++) {
        if (data[i].length) {
            transformedData.push(data[i][data[i].length - 1]);
        }
    }

    return transformedData;
}

export { transformCascadeData }